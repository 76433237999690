.container {
  position: absolute;
  background-color: transparent;
  width: 100vw;
}

.headerActions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.logo {
  width: 200px;
}

.headerButton {
  background-color: transparent;
  outline: none;
  border: none;
  margin-right: 10px;
  color: white;
}
