.container {
  background: #fff;
  width: 100%;
  padding: 0px;
  margin: 0px;
  box-shadow: 0px 1px 4px 0px #ccc;
  z-index: 1;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  float: right;
  font-size: 1.5rem;
  align-self: end;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 8rem;
}

.menuIcon {
  cursor: pointer;
  font-size: 1.75rem;
  padding-left: 1rem;
}

.cartLogo {
  color: #212121;
  font-size: 20px;
  padding-right: 1rem;
}

.dropDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
  border-radius: 10px;
  background-color: #1890ff;
  color: white;
  font-size: 15px;
  padding: 5px;
}

.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 20px;
  padding-left: 1rem;
}

.menuItem {
  border: none;
  outline: none;
  display: block;
  font-size: 20px;
  height: 60px;
  border-bottom: 0.5px solid #e5e7ea;
  width: 100%;
  text-align: left;
}

.closeIcon {
  border: none;
  outline: none;
  display: block;
  font-size: 26px;
  height: 60px;
  float: right;
}