.container {
  background: #1f3d57;
  height: 8vh;
  line-height: 8vh;
  padding-left: 16px;
  padding-right: 16px;
  box-shadow: 0px 1px 4px 0px #ccc;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.buttonContainer {
  display: flex;
  float: right;
  font-size: 1.5rem;
  align-self: end;
  height: 100%;
  justify-content: space-between;
  align-items: center;
}

.logo {
  width: 10rem;
}

.menuIcon {
  cursor: pointer;
  font-size: 1.75rem;
  margin-left: 1rem;
}

.cartLogo {
  color: white;
  font-size: 24px;
}

.dropDownIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background-color: #ffffff;
  color: #1890ff;
  font-size: 20px;
  margin-left: 1rem;
}

.headerIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  outline: none;
  font-size: 20px;
  margin-left: 0.6rem;
}

.menuItem {
  border: none;
  outline: none;
  display: block;
  font-size: 20px;
  height: 60px;
  border-bottom: 0.5px solid #e5e7ea;
  width: 100%;
  text-align: left;
}

.closeIcon {
  border: none;
  outline: none;
  display: block;
  font-size: 26px;
  height: 60px;
  float: right;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .logo {
      width: 4rem;
  }
}
