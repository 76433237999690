.bannerStyle {
  border-radius: 4px;
  padding: 15px;
  font-size: 20px;
  color: white;
  background-size: cover;
  background-position: left;
  margin: 20px;
  margin-bottom: 0;
}

.alignVertical {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    color: #1890ff;
    font-weight: 600;
    border-radius: 4px;
  }
}

.heroBannerStyle {
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
  background-color: white;
  background-size: cover;
}

.scheduleDemoButton {
  font-size: 16px;
  font-weight: 300;
  border-radius: 4px;
  color: #ffffff;
  width: 108px;
  height: 19px;
  text-align: center;
}

.light {
  width: 80%;

  h1 {
    color: #ffffff;
    font-size: 45px;
    line-height: 55px;
    font-weight: 600;
  }

  h4 {
    color: #ffffff;
    font-size: 24px;
    line-height: 29px;
    font-weight: 200;
  }

  p {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
  }
}

.dark {
  width: 80%;

  h1 {
    color: #177dc2;
    font-size: 45px;
    line-height: 55px;
    font-weight: 600;
  }

  h4 {
    color: #595959;
    font-size: 24px;
    line-height: 29px;
    font-weight: 300;
  }

  p {
    color: #595959;
    font-size: 18px;
    font-weight: 400;
  }
}

.heroBannerChildStyle {
  padding: 60px;
}

.weAreDiffBannerStyle {
  background-position: right;
  background-repeat: no-repeat;
  height: 550px;
  background-color: white;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.weAreDiffBannerChildStyle {
  width: 900px;
  text-align: center;

  h1 {
    font-size: 24px;
    line-height: 29px;
  }

  h4 {
    font-size: 36px;
    line-height: 44px;
  }
}

.scheduleDemoButton {
  padding: 15px 50px;
  font-size: 16px;
  line-height: 19px;
  border-radius: 4px;
  width: auto;
  height: auto;
}

.apnBenefits {
  width: 100%;
  margin: auto;
  padding: 100px;
  background-color: #ffffff;
  text-align: center;

  h1 {
    font-size: 24px;
    line-height: 29px;
    color: #177dc2;
    text-align: center;
    font-weight: bold;
    margin-bottom: 10px;
  }
}

.apnBenefitsRow {
  margin-top: 20px;
}

.apnBenefitsCol {
  width: 245px;

  img {
    padding: 5px 5px 15px 5px;
    width: 100px;
    height: 100px;
  }

  h3 {
    font-size: 24px;
    line-height: 29px;
    color: #177dc2;
    font-weight: 400;
  }

  p {
    font-size: 16px;
    line-height: 19px;
    color: #595959;
    width: 245px;
    margin: auto;
    font-weight: 400;
  }
}

@media (max-width: 768px) {
  .heroBannerChildStyle {
    padding: 10px;
  }

  .heroBannerStyle {
    height: 100%;
    background: content-box;
  }

  .weAreDiffBannerChildStyle {
    padding-left: 10px;
    padding-right: 10px;
    width: 100%;

    h4 {
      font-size: 30px;
    }
  }

  .apnBenefits {
    padding: 70px;
  }

  .apnBenefitsCol {
    margin: 20px 0px;
  }

  .dark,
  .light {
    width: 100%;
  }
}
