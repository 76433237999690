.mobileContainer {
  min-height: 500px;
  width: 100%;
  background-color: white;
  position: relative;
}

.loaderAndEmptyState {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 100%;
  position: absolute;
  background-color: white;
}
