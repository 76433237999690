$primary-color: #0D2A76;

.container {
  display: flex;
  justify-content: flex-end;
}

.conditionSelector {
  font-size: 80%;
  text-transform: uppercase;
  font-weight: bold;
  color: $primary-color;
  width: 100%;

  button {
    height: 30px;
    width: 30px;
    margin-right: 5px;
    padding: 0;
    border-radius: 4px;
  }
}

//.conditionSelector {
//  display: inline;
//};

.selected, .selected:hover {
  border-color: #E64833;
  background-color: #E64833;
  color: white;
  font-weight: 800;
}

.conditionHeader {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.conditionHeader {
  font-size: 16px;
}

@media (max-width: 600px) {
  .conditionSelector {
    font-size: 20px;
    button {
      font-size: 12px;
      margin-left: 0;
      margin: 5px;
    }
  }
  .container {
    display: unset;
  }
}
