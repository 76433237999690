$primary-color: #1890ff;

.serialNumber {
  color: $primary-color;
  font-size: 18px;
  font-weight: 600;
}

.labelHeader {
  color: #bdbdbd;
  font-weight: 600;
  font-size: 14px;
}
