.bannerStyle {
  padding: 40px;
}

.bannerColStyle {
  margin: auto;
}

.bannerImageStyle {
  width: 500px;
  padding: 10px;
}

@media (max-width: 768px) {
  .bannerStyle {
    padding: 20px 5px;
  }

  .bannerImageStyle {
    width: 320px;
  }
}
