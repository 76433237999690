// via https://codepen.io/ControlledChaos/pen/oYgeoz
.video {
  flex-grow: 1;
  position: relative;
  overflow: hidden;
  width: 100%;
  border: solid 0.2em transparent;
  max-height: 500px;
  max-width: 500px;
  & a {
    outline: none;
  }

  & figure {
    display: block;
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    margin: 0;

    & img {
      display: block;
      max-width: 100%;
      height: auto;
      //transform: scale(0.5);
      transition: all .20s;
    }

  }

  &:hover {
    & figure {
      & img {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &:hover:before {
    background-color: rgba(0,0,0,0.3);
  }
}

.card {
  padding: 0;
}

//@media screen and (max-width: 800px) {
//	.video {
//		width: 50%;
//		width: -webkit-calc(100% / 2);
//		width: calc(100% / 2);
//	}
//}

@media screen and (max-width: 640px) {
	.video {
		width: 100%;
	}
}
