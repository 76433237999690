$primary-color: #1890ff;

.actionColumn {
}

.imageBlock {
  display: inline-block;
  height: 130px;
  width: 120px;
  margin-right: 5px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  margin-top: 10px;
  border: 0.5px solid #212121;
}

.label {
  font-size: 12px;
}

.serialNumber {
  color: $primary-color;
  font-size: 16px;
  font-weight: 400;
}

.rowContainer {
  min-height: 80px;
}

.titleRowContainer {
  min-height: 80px;
  padding: 10px 0;
}

.titleColSerialNumber {
  margin-top: 5px;
  color: $primary-color;
  font-size: 16px;
  font-weight: 400;
}

.actionBtnCol {
  display: flex;
  justify-content: flex-end;
}

.qtyBox {
  background-color: #1890ff;
  border-radius: 50px;
  width: 31px;
  margin: 0;
  height: 23px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  line-height: 23px;
}

.mobileListCard {
  background-color: #ffffff;
  width: 100%;
  padding: 0px 10px;
}

.conditionCodes {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobileContainer {
  background-color: white;
  min-height: 500px;
}

.actionButton {
  border: none;
  background: #E5E7EA !important;
  color: $primary-color !important;
  font-size: 18px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.sortFilterContainer {
  background: #E5E7EA;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;
}

.closeButton {
  width: 100%;
  background-color: $primary-color !important;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .conditionCodes {
    margin-top: 10px;
  }
}

.viewStockList {
  background-color: #ffffff;
  width: 100%;
}

.viewStockRow {
  padding: 10px 0;
}

.ccBox {
  background-color: #1890ff;
  border-radius: 22px;
  width: 44px;
  padding: 10px;
  margin: 0;
  height: 44px;
  text-align: center;
  vertical-align: middle;
  color: #ffffff;
  line-height: 32px;
}
